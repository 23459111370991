/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./Footer"
import "typeface-open-sans"
import "../scss/argon.scss"

const Layout = ({originalPath, children}) => (
  <div className="d-flex flex-column min-vh-100">
    <Header originalPath={originalPath}/>
    <main className="flex-grow-1">{children}</main>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
