import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next";

import Separator from "../separator"

const Hero = ({withText, small}) => {
  const { t } = useTranslation('hero');
  let container;
  if (withText) {
    container = (
      <Container className="py-lg-md d-flex">
        <div className="px-0">
          <Row>
            <Col lg={8}>
              <h1 className="display-3  text-white">{t("title")}
                <span>{t("subtitle")}</span>
              </h1>
              <p className="lead  text-white">{t("description")}</p>
            </Col>
          </Row>
        </div>
      </Container>
    );
  } else {
    container = <></>;
  }
  return (
    <section className={`section section-lg section-shaped ${small ? '' : 'pb-250'}`}>
      <div className="shape shape-style-1 bg-gradient-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {container}
      <Separator fill="fill-white"/>
    </section>
  );
}

export default Hero;
