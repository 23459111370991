import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { IconContext } from "react-icons"
import {
  FaLanguage,
  FaUsers,
  FaLinkedin,
  FaBuffer,
  FaEnvelope,
} from "react-icons/fa"
import { IoIosSettings } from "react-icons/io"

import Headroom from "react-headroom"
import {
  Row,
  Col,
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Button,
} from "react-bootstrap"
import { Linki18n, localizeLink } from "./Linki18n"
import FlagIcon from "./FlagIcon.js"

const Header = ({ originalPath }) => {
  const { t, i18n } = useTranslation("header")

  const handleChangeLanguage = lang => {
    if (typeof Storage !== "undefined") {
      window.localStorage.setItem("lang", lang)
    }
  }

  return (
    <Headroom style={{ zIndex: 10 }}>
      <header className="header-global">
        <IconContext.Provider
          value={{
            style: {
              verticalAlign: "middle",
              marginBottom: 2,
              width: "1.2em",
              height: "1.2em",
            },
          }}
        >
          <Navbar expand="lg" className="navbar-transparent py-1">
            <Container>
              <Navbar.Brand
                className="mr-lg-5 font-weight-bold"
                href={localizeLink(i18n.language, "/")}
              >
                IOD
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse id="main-navbar-collapse">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col xs={6} className="collapse-brand">
                      <Linki18n to="/">IOD</Linki18n>
                    </Col>
                    <Col xs={6} className="collapse-close">
                      <Navbar.Toggle>
                        <span></span>
                        <span></span>
                      </Navbar.Toggle>
                    </Col>
                  </Row>
                </div>
                <Nav className="align-items-lg-center">
                  <Nav.Link href={localizeLink(i18n.language, "/#services")}>
                    <FaBuffer className="d-lg-none" /> {t("Services")}
                  </Nav.Link>
                  {/* <Nav.Link href="#news"><i className="ni ni-ui-04 d-lg-none"></i> News</Nav.Link> */}
                  <Nav.Link href={localizeLink(i18n.language, "/#howwework")}>
                    <IoIosSettings className="d-lg-none" /> {t("HowWeWork")}
                  </Nav.Link>
                  <Nav.Link href={localizeLink(i18n.language, "/#team")}>
                    <FaUsers className="d-lg-none" /> {t("Team")}
                  </Nav.Link>
                  <Nav.Link href={localizeLink(i18n.language, "/articles")}>
                    <FaUsers className="d-lg-none" /> {t("Articles")}
                  </Nav.Link>
                  <NavDropdown
                    title={
                      <>
                        <FaLanguage className="fa-lg d-lg-none" />{" "}
                        <span>{t("Languages")}</span>
                      </>
                    }
                    id="language-dropdown"
                  >
                    <NavDropdown.Item
                      href={localizeLink("en", originalPath, true)}
                      onClick={() => handleChangeLanguage("en")}
                    >
                      <FlagIcon code="gb" className="mr-1" /> {t("English")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href={localizeLink("fr", originalPath, true)}
                      onClick={() => handleChangeLanguage("fr")}
                    >
                      <FlagIcon code="fr" className="mr-1" /> {t("French")}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto">
                  <Nav.Link
                    className="nav-link-icon"
                    href="https://www.linkedin.com/company/iod-coop/"
                    target="_blank"
                  >
                    <FaLinkedin />
                    <span className="nav-link-inner--text d-lg-none">
                      Linkedin
                    </span>
                  </Nav.Link>
                  <Nav.Item className="d-none d-lg-block ml-lg-4">
                    <Button
                      className="btn-icon"
                      variant="neutral"
                      href="mailto:contact@iod.ai"
                    >
                      <span className="btn-inner--icon">
                        <FaEnvelope className="mr-2" />
                      </span>
                      <span className="nav-link-inner--text">
                        {t("ContactUs")}
                      </span>
                    </Button>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </IconContext.Provider>
      </header>
    </Headroom>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
