import React from "react"

const Separator = (props) => (
  <div className="separator separator-bottom separator-skew">
    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon className={props.fill} points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
);

export default Separator;