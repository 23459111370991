import i18n from "i18next";
import moment from "moment";

export const defaultLocale = "en";
export const defaultNamespace = "common";

export const options = {
  fallbackLng: defaultLocale,
  ns: [defaultNamespace],
  defaultNS: defaultNamespace,
  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
    format: function(value, format, lng) {
      if(value instanceof Date) return moment(value).format(format);
      return value;
    }
  },

  react: {
    wait: false
  },

  initImmediate: false // Important for SSR to work
};

export default () => {
  i18n.init(options);

  return i18n;
};
