import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

const defaultLocale = "en";

export const localizeLink = (locale, link, fromHeader = false) => {
  if (locale !== defaultLocale) {
    if (/^\/articles\/.+$/.test(link) && fromHeader){
      return `/${locale}/articles`;
    } else {
      return `/${locale}${link}`;
    }
  } else {
    if (/^\/articles\/.+$/.test(link) && fromHeader){
      return `/articles`;
    } else {
      return link;
    }
  }
}

export const Linki18n = (props) => {
  const { i18n } = useTranslation()
  const locale = i18n.language;
  
  const {to, ...restProps} = props;

  const newLink = localizeLink(locale, to);

  return (
    <Link
      to={newLink}
      {...restProps}>
      {props.children}
    </Link>
  );
};
