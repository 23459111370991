import React, { useState } from "react"

import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap"
import {useTranslation} from "react-i18next"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { IoIosMail } from "react-icons/io"
import axios from "axios"
import validator from "email-validator"

import DaskellLogo from "../images/partenaires/daskell.png"
import AminLogo from "../images/partenaires/amin.png"
import LaForgeLogo from "../images/partenaires/laforge.png"
import BlueLionsLogo from "../images/partenaires/bluelions.png"
import OlympeLogo from "../images/partenaires/olympe_white.png"
import ElseemedLogo from "../images/partenaires/elseemed.png"

const Footer = () => {
  const { t, i18n } = useTranslation('footer');

  // states
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault()
    event.stopPropagation()
    if (validator.validate(email)) {
      setDisabled(true);
      try {
        const response = await axios.post(
          "https://q27n3114m4.execute-api.eu-central-1.amazonaws.com/default/subscribeNewsletter",
          {
            to: email,
            lang: i18n.language || window.localStorage.i18nextLng || '',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Api-Key': '0OIYXOLzyz6v6rkdSXC464IjE9YKLW3y5R5w6SPu'
            },
          }
        );
        if (response.status === 200) {
          setDisabled(false);
          setIsValid(true);
        } else {
          setDisabled(false);
          setIsInvalid(true);
        }
      } catch (error) {
        setDisabled(false);
        setIsInvalid(true);
      }
    } else {
      setIsInvalid(true);
    }
  };

  const handleChange = event => {
    setEmail(event.target.value);
    setIsValid(false);
    setIsInvalid(false);
  }

  return (
    <footer className="footer bg-default">
      <Container>
        <Row className="row-grid my-md">
          <Col lg={6}>
            <h3 className="text-white font-weight-light mb-2">{t("contact_us")}</h3>
            <div className="btn-wrapper">
              <a href="mailto:contact@iod.ai?subject=Project%20Request&body=Hi%2C%0A%0AI%20have%20a%20project%20I%20would%20like%20to%20submit%20to%20IOD.%20My%20project%20is%20about%20...%0A%0ACheers%2C%0A..." className="btn btn-primary btn-icon mb-3 mb-sm-0">
                <span className="btn-inner--text">{t("haveproject")}</span>
              </a>
              <a href="mailto:contact@iod.ai?subject=Joining%20Request&body=Hi%2C%0A%0AI%20would%20like%20to%20join%20IOD%20as%20a%20IODer%2C%20I%20am%20...%0A%0ACheers%2C%0A..." className="btn btn-white btn-icon mb-3 mb-sm-0">
                <span className="btn-inner--text">{t("wantjoin")}</span>
              </a>
            </div>
            <br/>
            <h3 className="text-white font-weight-light mb-2">{t("newsletter")}</h3>
            <Form onSubmit={e => handleSubmit(e)}>
              <Form.Group className="my-3 mr-3">
                <InputGroup className="input-group-alternative">
                  <InputGroup.Prepend>
                    <span className="input-group-text">
                      <IoIosMail />
                    </span>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder="Email"
                    type="email"
                    isInvalid={isInvalid}
                    isValid={isValid}
                    disabled={disabled}
                    value={email}
                    onChange={e => handleChange(e)}
                  />
                  <InputGroup.Append>
                    <Button type="submit" variant="primary" disabled={disabled}>{ t("subscribe") }</Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form>
            <p className="small text-white">{t("newsletter_desc")}</p>
          </Col>
          <Col lg={6}>
            <h3 className="text-white font-weight-light mb-2">{t("partners")}</h3>
            <OutboundLink className="mx-2" href="https://www.daskell.com" target="_blank" rel="noopener noreferrer">
              <img src={DaskellLogo} target="_blank" alt="Logo of Daskell" style={{width: "120px"}}/>
            </OutboundLink>
            <OutboundLink className="mx-2" href="https://www.aminkaboli.com/" target="_blank" rel="noopener noreferrer">
              <img src={AminLogo} target="_blank" alt="Logo of Amin Kaboli" style={{width: "120px"}}/>
            </OutboundLink>
            <OutboundLink className="mx-2" href="https://epfl-innovationpark.ch/laforge" target="_blank" rel="noopener noreferrer">
              <img src={LaForgeLogo} target="_blank" alt="Logo of laforge" style={{width: "120px"}}/>
            </OutboundLink>
            <OutboundLink className="mx-2" href="https://bluelion.ch" target="_blank" rel="noopener noreferrer">
              <img src={BlueLionsLogo} target="_blank" alt="Logo of blue lions" style={{width: "120px"}}/>
            </OutboundLink>
            <OutboundLink className="mx-2" href="https://olympe.ch" target="_blank" rel="noopener noreferrer">
              <img src={OlympeLogo} target="_blank" alt="Logo of Olympe" style={{width: "120px"}}/>
            </OutboundLink>
            <OutboundLink className="mx-2" href="https://elseemed.ch" target="_blank" rel="noopener noreferrer">
              <img src={ElseemedLogo} target="_blank" alt="Logo of Elseemed" style={{width: "120px"}}/>
            </OutboundLink>
          </Col>
        </Row>
        <hr />
        <Row className="row align-items-center justify-content-md-between">
          <Col md={6}>
            <div className="copyright text-white">
              © {new Date().getFullYear()} IOD.
            </div>
          </Col>
          <Col md={6}>
            <ul className="nav nav-footer justify-content-end">
              <li className="nav-item">
                <OutboundLink href="http://www.freepik.com" className="nav-link" target="_blank" rel="noopener noreferrer">{t("vectorsref")} vectorpouch</OutboundLink>
              </li>
              <li className="nav-item">
                <OutboundLink href="https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md" className="nav-link" target="_blank" rel="noopener noreferrer">{t("licenseref")}</OutboundLink>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
